.header {
  width: 100%;

}

.main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 2rem;
  min-height: 90vh;
  width: 100%;
  margin-left: 25px;
  margin-right: 25px;
}

.splash {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  min-height: 90vh;
  width: 100%;
}

.landingBackground {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(#1985d2, #093459);
}

.landing {
  width: 100%;
  min-height: 100vh;
}

.loggedOut {
  width: 100%;
  min-height: 100vh;
}

.loggedIn {
  width: 100%;
  min-height: 100vh;

}

.glass {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: .25rem 0rem;
  padding: 0.5rem 0rem;
  background: rgba(255, 255, 255, 0.06);
  border-radius: 16px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  border: 1px solid rgba(14, 70, 113, 0.2);
}

.promoImage {
  position: relative;
  margin: .25rem;
  height: 59.3px;
  width: 200px;
}


@media only screen and (max-width: 600px) {
  .promoImage {
    height: 29.65px;
    width: 100px;
  }
}

.logoImage {
  position: relative;
  height: 50px;
  width: 50px;
}

.promoText {
  color: #fff;
  margin-bottom: 1rem;
  text-align: center;
}

.authMain {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 90vh;
  width: 100%;
  /* background-color: aqua; */
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--bike-orange);
  width: 100%;
  text-align: center;
  height: 5rem;
}

.logo {
  margin-left: 11px;
  margin-right: 11px;
}

.navLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  padding: .5rem;
  height: 100%;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.navLogout {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin: 1px; */
  /* padding: .5rem; */
  height: 100%;
  width: 50%;
}

.navItem {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin: 5px; */
  padding: .5rem;
  height: 100%;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.navItem:hover {
  background-color: #fbc067;

}

.currentPath {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .5rem;
  /* margin: 5px; */
  background-color: var(--bike-blue);
  color: var(--bike-white);
  height: 100%;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.currentPath:hover {
  background-color: #4083b3;
}



.bikeTableContainer {
  max-height: 500px;
  overflow-y: scroll;
  width: 100%;
  border: 1px solid lightgrey;
  scrollbar-color: var(--bike-blue) transparent;
  scrollbar-width: thin;

}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 25px;
  /* background-color: aqua; */
  width: 100%;
  height: 100%;
}

.flexCol {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  padding: 1rem;
}

.columnWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  /* background-color: lime; */
  padding: .5rem;

}


.leftColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 75%;
  /* background-color: orange; */
  padding: .5rem;


}

.rightColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 50%;
  /* background-color: blueviolet; */
  padding: .5rem;


}

.column {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 10px;
  padding: .25rem;
  width: 99%;
}



.customTextField textarea::placeholder {
  font-weight: 500;
  opacity: .5;

}

.dialogContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 400px;
  height: 100%;
  width: 100%;
  padding: 1.5rem;
  overflow-y: scroll;
  scrollbar-color: var(--bike-blue) transparent;
  scrollbar-width: thin;
}

.dialogScroll {
  min-height: 500px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 1rem;
  width: 100%;
}

.dialogContainerRight {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-end;
  min-height: 400px;
  height: 100%;
  width: 100%;
  padding: 1.5rem;

}

.dialogContainerSmall {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  min-height: 400px;
  height: 100%;
  width: 100%;
  padding: 1.5rem;

}

.dialogContent {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  min-height: 100%;
  margin-bottom: 1rem;

}

.dialogContentLeft {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  min-height: 100%;
  margin-bottom: 1rem;
}

.dialogTopRow {
  width: 100%;
  /* height:100%; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;


}

.dialogButtons {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 50%;
  margin-top: 2rem;
}

.dashboardTopRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.dialogSplitCol {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;

}

.contentSplitCol {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;

}

.contentLeft {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  min-height: 100%;
  margin-bottom: 1rem;
  padding: 1.5rem;
}

.splitContentLeft {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  min-height: 100%;
  margin-bottom: .25rem;
  padding: .5rem;
}

.scrollSideways {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-x: scroll;
  min-width: 950px;
  width: 100%;
  min-height: 220px;
  height: 100%;
  padding: 1rem .5rem;
  scrollbar-color: var(--bike-blue) transparent;
}

.innerScroll {
  display: flex;
  align-items: center;
  justify-content: space-around;
  overflow-x: hidden;
  border: 1px solid lime;
  width: 100%;
  /* min-height:300px; */
  height: 100%;

}

.cardWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: .2rem;

}

.stickyColumn {
  position: sticky;
  left: 0;
  background-color: var(--bike-white);
  z-index: 9999 !important;
}

.mb {
  margin-bottom: 1rem;
}

.halfPad {
  padding: .5rem;
}

.fileInput:hover {
  cursor: pointer;
}

.mobileNavWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 100;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.mobileNavMenu {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  z-index: 100;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;


}

.hamburgerMenu {
  width: 33%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.hamburgerLine {
  width: 100%;
  height: 2px;
  border: 1px solid yellow;


}

.dropdownMenu {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  z-index: 100;
  background-color: var(--bike-white);
  border-left: 2px solid var(--bike-blue);
  border-right: 2px solid var(--bike-blue);
  border-bottom: 2px solid var(--bike-blue);
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;



}

.mobileNavItem {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  height: 100%;
  width: 100%;
  text-align: center;
  font-size: small;
}



.mobileCurrentPath {
  display: flex;
  justify-content: center;
  text-align: flex-end;
  font-size: small;
  padding: 1rem;
  /* margin: 5px; */
  background-color: var(--bike-blue);
  color: var(--bike-white);
  height: 100%;
  width: 100%;
}

.mobileCurrentPath:focus {
  background-color: #4083b3;
}

.resourceRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

}

.dataBtn {
  border: 2px solid var(--bike-blue);
  padding: 10px;
  border-radius: 8px;
  box-shadow: 6px 4px grey;

}

.dataBtn:hover {
  cursor: pointer;
  border: 2px solid var(--bike-orange);
  scale: 1.05;
  box-shadow: 5px 2px grey;
}

.dataBtn:active {
  cursor: pointer;
  scale: 1;
}